@use "sass:map";

$theme-colors: (
  "primary": #0D9CDE,
  /*"secondary":  #ff0000,
  "success":    #ff0000,
  "info":       #ff0000,
  "warning":    #ff0000,
  "danger":     #ff0000,
  "light":      #ff0000,*/
  "completed": #1ABA00,
  "dark": #263746
);

@import "/node_modules/bootstrap/scss/bootstrap.scss";
@import url("https://fonts.googleapis.com/icon?family=Material+Icons|Material+Icons+Outlined");

@import '@angular/material/theming';
@include mat-core();


/* For use in src/lib/core/theming/_palette.scss */
$my-blue: (
  50 : #007bff,
  100 : #007bff,
  200 : #007bff,
  300 : #007bff,
  400 : #007bff,
  500 : #007bff,
  600 : #007bff,
  700 : #007bff,
  800 : #007bff,
  900 : #007bff,
  A100 : #007bff,
  A200 : #007bff,
  A400 : #007bff,
  A700 : #007bff,
  contrast: (50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);


// below creates a primary palette with three shades of blue
$my-app-primary: mat-palette($my-blue, 100, 50, 200);
$my-app-accent: mat-palette($mat-blue-grey);
$my-app-theme: mat-light-theme($my-app-primary, $my-app-accent);
@include angular-material-theme($my-app-theme);

@import '@angular/material/prebuilt-themes/deeppurple-amber.css';
// @import "assets/custom.scss";


$primary: #0D9CDE;
$dark: #263746;
$completed: #5BC05F;

body div:not(mat-icon) {
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, Liberation Sans, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", Segoe UI Symbol, "Noto Color Emoji";
}

.app-container {
  flex-grow: 1;
}


.main-nav .material-icons {
  font-size: 30px;
}

.navbar-brand {
  font-weight: bold;
  margin-left: 20px;
}

.nav-link {
  cursor: pointer;
  user-select: none;
}


.full-height {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.profile-menu {

  .profile-info {
    text-align: center;
    padding: 20px 20px 10px 20px;
  }

  .full-name {
    font-weight: bold;
    font-size: 20px;
  }

  .email {
    color: #777;
    font-size: 14px;
  }

  .menu-btn-white {
    color: $primary;
    justify-content: center;
    display: flex;
  }

  .menu-btn-primary {
    background-color: $dark;
    justify-content: center;
    text-align: center;
    color: white;
  }

  .menu-btn-primary * {
    font-weight: bold;
    color: white;
  }

  .menu-btn-primary span {
    margin: 20px 0;
  }

  .menu-btn-primary:hover:not([disabled]),
  .menu-btn-primary.cdk-program-focused:not([disabled]),
  .menu-btn-primary.cdk-keyboard-focused:not([disabled]),
  .menu-btn-primary-highlighted:not([disabled]) {
    background: black;
  }

  .mat-mdc-menu-content {
    padding-bottom: 0;
  }
}

.success-snackbar {
  box-sizing: border-box;
  height: 41px;
  width: 1367px;
  border: 1px solid #2FA033;
  background-color: #5BC05F;
  color: rgb(255, 255, 255);
}

.error-snackbar {
  box-sizing: border-box;
  height: 41px;
  width: 1367px;
  border: 1px solid #FF455B;
  background-color: #C21241;
  color: rgb(255, 255, 255);
}

.error-snackbar .mat-simple-snackbar-action {
  color: rgb(255, 255, 255);
}


.artifact-download-progress-bar {
  height: 10px;
  background: #eee;
  max-width: 300px;
  position: relative;
}

.artifact-download-progress {
  background-color: $primary;
  height: 10px;
  width: 0px;
}

.artifact-download-progress-text {
  font-size: 14px;
}

.artifact-download-infiniteprogress {
  position: absolute;
  top: 0;
  right: 100%;
  bottom: 0;
  left: 0;
  background: $primary;
  width: 0;
  animation: borealisBar 2s linear infinite;
}

@keyframes borealisBar {
  0% {
    left: 0%;
    right: 100%;
    width: 0%;
  }

  10% {
    left: 0%;
    right: 75%;
    width: 25%;
  }

  90% {
    right: 0%;
    left: 75%;
    width: 25%;
  }

  100% {
    left: 100%;
    right: 0%;
    width: 0%;
  }
}